import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import './customTableStyles.css';  // Import the CSS file

const ClassificationCertaintyAnalysis = ({ data = [] }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      createLineChart();
    }
  }, [data]);

  const createLineChart = () => {
    const svg = d3.select(chartRef.current)
      .attr('width', 500)
      .attr('height', 400);

    const margin = { top: 20, right: 30, bottom: 30, left: 50 };
    const width = +svg.attr('width') - margin.left - margin.right;
    const height = +svg.attr('height') - margin.top - margin.bottom;

    svg.selectAll('*').remove();  // Clear previous chart

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map(d => d.classification))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.max_certainty)])
      .nice()
      .range([height, 0]);

    const line = d3.line()
      .x(d => x(d.classification) + x.bandwidth() / 2)
      .y(d => y(d.avg_certainty));

    // Draw the average certainty line
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#4caf50')
      .attr('stroke-width', 2)
      .attr('d', line);

    // Draw dots for each classification
    g.selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => x(d.classification) + x.bandwidth() / 2)
      .attr('cy', d => y(d.avg_certainty))
      .attr('r', 4)
      .attr('fill', '#4caf50');

    // Add X-axis and Y-axis
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    g.append('g')
      .call(d3.axisLeft(y));
  };

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Classification Certainty Analysis
      </Typography>
      <p>This chart shows the average certainty for each classification, along with min and max certainty.</p>
      <svg ref={chartRef}></svg>

      {/* Table for displaying min and max certainty */}
      <Typography variant="h5" align="center" gutterBottom>
        Min and Max Certainty per Classification
      </Typography>
      <TableContainer component={Paper} className="custom-table-container">
        <Table>
          <TableHead className="custom-table-head">
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} className="custom-table-head-cell">Classification</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} className="custom-table-head-cell">Avg Certainty</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} className="custom-table-head-cell">Min Certainty</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} className="custom-table-head-cell">Max Certainty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.classification} className="custom-table-row">
                <TableCell className="custom-table-cell">{row.classification}</TableCell>
                <TableCell className="custom-table-cell">{row.avg_certainty.toFixed(2)}</TableCell>
                <TableCell className="custom-table-cell">{row.min_certainty.toFixed(2)}</TableCell>
                <TableCell className="custom-table-cell">{row.max_certainty.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ClassificationCertaintyAnalysis;

import React, { useState, useEffect } from 'react';
import EmailClassificationPerUser from './EmailClassificationPerUser';
import TopPhishingDomains from './TopPhishingDomains';
import ClassificationSummary from './ClassificationSummary';
import ClassificationCertaintyAnalysis from './ClassificationCertainty';
import EmailContentAnalysis from './EmailContentAnalysis';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';

const AllReportsPage = () => {
  const { user } = useAuth0();
  const [data, setData] = useState({
    emailClassificationPerUserData: [],
    EmailContentAnalysisData: [],
    topPhishingDomainsData: [],
    classificationSummaryData: [],
    classificationCertaintyData: []
  });

  const fetchAllData = async (tableName) => {
    try {
      const responses = await Promise.all([
        fetch('/api/email-classification-per-user', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tableName }),
        }),
        fetch('/api/email-content-analysis', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tableName }),
        }),
        fetch('/api/top-phishing-domains', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tableName }),
        }),
        fetch('/api/classification-summary', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tableName }),
        }),
        fetch('/api/classification-certainty', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tableName }),
        }),      
      ]);

      const dataResponses = await Promise.all(responses.map((response) => response.json()));

      setData({
        emailClassificationPerUserData: dataResponses[0],
        EmailContentAnalysisData: dataResponses[1],
        topPhishingDomainsData: dataResponses[2],
        classificationSummaryData: dataResponses[3],
        classificationCertaintyData: dataResponses[4],
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFetchData = () => {
    if (user) {
      const customClaimTableName = user['https://report.whatthephish.ai/table_name'];
      fetchAllData(customClaimTableName);
    }
  };

  return (
    <div>
      <div className="centered-buttons">
      <LogoutButton />
      </div>
      <h1>All Reports</h1>
      <div className="centered-buttons">
      <Button variant="contained" color="primary" onClick={handleFetchData}>
        Fetch All Data
      </Button></div>
      
      <section>
        <EmailClassificationPerUser data={data.emailClassificationPerUserData} />
      </section>

      <section>
        <EmailContentAnalysis data={data.EmailContentAnalysisData} />
      </section>

      <section>
        <TopPhishingDomains data={data.topPhishingDomainsData} />
      </section>

      <section>
        <ClassificationSummary data={data.classificationSummaryData} />
      </section>

      <section>
        <ClassificationCertaintyAnalysis data={data.classificationCertaintyData} />
      </section>

      
    </div>
  );
};

export default AllReportsPage;

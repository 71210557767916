import React, { useRef, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as d3 from 'd3';

const TopPhishingDomains = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      createBarChart();
    }
  }, [data]);

  const createBarChart = () => {
    const svg = d3.select(chartRef.current)
      .attr('width', 600)
      .attr('height', 400);

    svg.selectAll('*').remove(); // Clear previous chart

    const margin = { top: 20, right: 30, bottom: 50, left: 100 };
    const width = +svg.attr('width') - margin.left - margin.right;
    const height = +svg.attr('height') - margin.top - margin.bottom;

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleBand()
      .domain(data.map(d => d.senderDomain))
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.phishing_count)])
      .nice()
      .range([height, 0]);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    g.append('g')
      .call(d3.axisLeft(y));

    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.senderDomain))
      .attr('y', d => y(d.phishing_count))
      .attr('width', x.bandwidth())
      .attr('height', d => height - y(d.phishing_count))
      .attr('fill', '#ff7043');
  };

  return (
    <div>
      <h1>Top Phishing Domains</h1>
      <p>This chart shows the top phishing domains based on the number of phishing emails.</p>
      <svg ref={chartRef}></svg>

      {/* Adding a table to display the phishing counts */}
      <h3>Top Phishing Domains and Counts</h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Sender Domain</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Phishing Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.senderDomain}>
                <TableCell>{row.senderDomain}</TableCell>
                <TableCell>{row.phishing_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopPhishingDomains;

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';



const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="whatthephish.us.auth0.com"
    clientId="pfMI5SkX9suOgWU99rE9msa5NB88mjhN"
    authorizationParams={{
      redirect_uri: 'https://report.whatthephish.ai/'
    }}
  >
    <App />
  </Auth0Provider>
);

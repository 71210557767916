import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LoginButton = () => {
  const { loginWithPopup, isAuthenticated, logout, user } = useAuth0();

  return (
    <div className="centered-buttons">
      {!isAuthenticated ? (
        <Button variant="contained" color="primary" onClick={() => loginWithPopup()}>Log into Reporting</Button>
      ) : (
        <>
          <h2>Welcome, {user.name}</h2>
          
        </>
      )}
    </div>
  );
};

export default LoginButton;

import React from 'react';
import LoginButton from './LoginButton';
import { Container, Typography } from '@mui/material';

const LoginPage = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h3" align="center" gutterBottom>
  Please Log In to Access Reports
     </Typography>
      <LoginButton />
    </Container>
  );
};

export default LoginPage;

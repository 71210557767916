import React, { useEffect, useState } from 'react';
import nlp from 'compromise';

const TopPhrases = ({ data = [], classification }) => {
  const [phrases, setPhrases] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const content = data.map(email => email.emailContent).join(' ');
      const extractedPhrases = extractTopPhrases(content);
      setPhrases(extractedPhrases);
    }
  }, [data]);

  const extractTopPhrases = (content) => {
    const doc = nlp(content);
    let phraseCounts = {};

    doc.nouns().out('array').forEach((phrase) => {
      const text = phrase.toLowerCase();
      phraseCounts[text] = (phraseCounts[text] || 0) + 1;
    });

    doc.people().out('array').forEach((entity) => {
      const text = entity.toLowerCase();
      phraseCounts[text] = (phraseCounts[text] || 0) + 1;
    });

    doc.places().out('array').forEach((entity) => {
      const text = entity.toLowerCase();
      phraseCounts[text] = (phraseCounts[text] || 0) + 1;
    });

    doc.organizations().out('array').forEach((entity) => {
      const text = entity.toLowerCase();
      phraseCounts[text] = (phraseCounts[text] || 0) + 1;
    });

    return Object.entries(phraseCounts).sort((a, b) => b[1] - a[1]).slice(0, 10);
  };

  return (
    <div>
      <h5>Top Phrases in {classification === 'phishing' ? 'Phishing' : 'Legitimate'} Emails</h5>
      {phrases.length > 0 ? (
        <ul>
          {phrases.map(([phrase, count]) => (
            <li key={phrase}>{phrase} - {count} occurrences</li>
          ))}
        </ul>
      ) : (
        <p>No phrases found</p>
      )}
    </div>
  );
};

export default TopPhrases;

import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const ClassificationSummary = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      createPieChart();
    }
  }, [data]);

  const createPieChart = () => {
    const svg = d3.select(chartRef.current)
      .attr('width', 500)
      .attr('height', 400)
      .append('g')
      .attr('transform', `translate(${250},${200})`);

    const radius = Math.min(500, 400) / 2;

    const pie = d3.pie()
      .value(d => d.email_count)  // Using email_count for pie chart values
      .sort(null);

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pieData = pie(data);

    svg.selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.classification));

    // Add labels for classifications
    svg.selectAll('text')
      .data(pieData)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${arc.centroid(d)})`)
      .attr('dy', '0.35em')
      .text(d => `${d.data.classification}: ${d.data.email_count}`);
  };

  return (
    <div>
      <h1>Classification Summary</h1>
      <p>This chart shows the email count distribution for each classification.</p>
      <svg ref={chartRef}></svg>

      {/* Adding a table to display classification and email counts */}
      <h3>Classification Email Count</h3>
      <table>
        <thead>
          <tr>
            <th>Classification</th>
            <th>Email Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.classification}>
              <td>{row.classification}</td>
              <td>{row.email_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassificationSummary;

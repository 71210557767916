import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import TopPhrases from './TopPhrases'; 

const EmailContentAnalysis = ({ data }) => {
  const phishingData = data.filter(email => email.classification === 'phishing');
  const legitimateData = data.filter(email => email.classification === 'legitimate');

  const extractKeywordFrequency = (content) => {
    const keywords = ['account', 'login', 'update', 'confirm', 'secure'];
    return keywords.map(keyword => ({
      keyword,
      count: (content.match(new RegExp(keyword, 'gi')) || []).length,
    }));
  };

  const senderDomains = Array.from(new Map(
    data.map(email => [email.senderDomain, (email.senderDomain || []).length])
  ));

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Email Content Analysis by Classification
      </Typography>

      {/* Top Phrases Section */}
      <TopPhrases content={phishingData.map(email => email.emailContent).join(' ')} classification="phishing" />
      <TopPhrases content={legitimateData.map(email => email.emailContent).join(' ')} classification="legitimate" />

      {/* Keyword Frequency Section */}
      <Typography variant="h5">Keyword Frequency in Phishing Emails</Typography>
      <ul>
        {extractKeywordFrequency(phishingData.map(email => email.emailContent).join(' ')).map(({ keyword, count }) => (
          <li key={keyword}>{keyword}: {count}</li>
        ))}
      </ul>

      <Typography variant="h5">Keyword Frequency in Legitimate Emails</Typography>
      <ul>
        {extractKeywordFrequency(legitimateData.map(email => email.emailContent).join(' ')).map(({ keyword, count }) => (
          <li key={keyword}>{keyword}: {count}</li>
        ))}
      </ul>

      {/* Sender Domains Section */}
      <Typography variant="h5">Top Sender Domains Frequently Associated with Phishing</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Sender Domain</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {senderDomains.map(([domain, count]) => (
              <TableRow key={domain}>
                <TableCell>{domain}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmailContentAnalysis;

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const EmailClassificationPerUser = ({ data }) => {
  return (
    <div>
      <h1>Email Classifications Per User</h1>
      <h3>Email Classification Counts</h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email Address</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Classification</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Classification Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={`${row.emailAddress}-${row.classification}`}>
                <TableCell>{row.emailAddress}</TableCell>
                <TableCell>{row.classification}</TableCell>
                <TableCell>{row.classification_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EmailClassificationPerUser;
